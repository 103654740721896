    // checks for the user login status in application
    document.addEventListener("DOMContentLoaded", function () {

        function showAnonymousButtons() {
            console.log("Not logged in");
        }

        function showAuthenticatedButtons() {
            console.log("Logged in");
            Array.from(document.getElementsByClassName("logged-in--removed")).
                forEach(function (e) { e.setAttribute("hidden", ""); });
            Array.from(document.getElementsByClassName("logged-in--added")).
                forEach(function (e) { e.removeAttribute("hidden"); });
        }

        var xhr = new XMLHttpRequest();

        // pass entire query string for anything containing utm parameter(s)
        var checkQStr = window.location.search;
        checkQStr = checkQStr.indexOf("utm_") > -1 ? checkQStr : "";

        xhr.open('GET', 'https://app.geektastic.com/auth/check' + checkQStr);
        xhr.withCredentials = true;

        xhr.onload = function () {
            if (xhr.status === 200) {
                let authStatus = JSON.parse(xhr.responseText);
                if (authStatus.authenticated == null || authStatus.authenticated === false) {
                    showAnonymousButtons();
                } else if (authStatus.authenticated != null && authStatus.authenticated === true) {
                    showAuthenticatedButtons();
                }
            }
            else {
                console.error('Could not fetch user login status. Call returned ' + xhr.status);
                showAnonymousButtons();
            }
        };
        xhr.onerror = function () {
            console.error('Could not fetch user login status.');
            showAnonymousButtons();
        };
        xhr.send();
    });